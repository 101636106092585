import React from 'react';
import { connect } from 'react-redux';

import { TooltipIcon } from 'carbon-components-react';
import { BadIcon, CircledMaintenanceIcon, GoodIcon, PartiallyAvailableIcon, UnknownIcon } from './listIcons';
import { isEIS, isSCIS, isNSONE } from '../Helper/ComponentHelper';

const HealthStatusLegend = (props) => {
  const iconGenerator = (index) => {
    switch (index) {
      case 0:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Available")}>
          <div className="legend">Available: <GoodIcon /></div>
        </TooltipIcon>
      case 1:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Unavailable")}>
          <div className="legend">Unavailable: <BadIcon /></div>

        </TooltipIcon>;
      case 2:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Maintenance")}>
          <div className="legend">Maintenance: <CircledMaintenanceIcon /></div>

        </TooltipIcon>;
      case 3:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Partially_Available")}>
          <div className="legend">Partially Available: <PartiallyAvailableIcon /></div>
        </TooltipIcon>;
      case 4:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Unknown")}>
          <div className="legend">Unknown: <UnknownIcon /></div>
        </TooltipIcon>;
      case 5:
        return <TooltipIcon
          direction="bottom"
          align="center"
          tooltipText={tooltipGenerator("Not Enabled")}>
          <div className="legendNA">Disabled: - </div>
        </TooltipIcon>;
      default:
        return '-';
    }
  }

  const tooltipGenerator = (healthStatus) => {
    switch (healthStatus) {
      case "Available":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.GOOD ?
          props.envSchema.healthStatusDefinition.GOOD : "System is available";
      case "Unavailable":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.BAD ?
          props.envSchema.healthStatusDefinition.BAD : "System is unavailable";
      case "Maintenance":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.MAINTENANCE ?
          props.envSchema.healthStatusDefinition.MAINTENANCE : "Maintenance in progress";
      case "Degraded":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.DEGRADED ?
          props.envSchema.healthStatusDefinition.DEGRADED : "System performance has been degraded"
      case "Partially_Available":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.PARTIALLY_AVAILABLE ?
          props.envSchema.healthStatusDefinition.PARTIALLY_AVAILABLE : "System is partially available"
      case "Unknown":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.UNKNOWN ?
          props.envSchema.healthStatusDefinition.UNKNOWN : "No monitoring data found";
      case "Not Enabled":
        return props.envSchema.healthStatusDefinition && props.envSchema.healthStatusDefinition.DISABLED ?
          props.envSchema.healthStatusDefinition.DISABLED : "Monitoring not enabled";
      default:
        return "";
    }
  }

  return (
    <div className="legendGrouping desktopOnly">
      <div className="legend">{iconGenerator(0)}</div>
      <div className="legend">{iconGenerator(1)}</div>
      <div className="legend">{iconGenerator(2)}</div>
      {(isSCIS(props.envSchema.solution) || isEIS(props.envSchema.solution)) || isNSONE(props.envSchema.id) && <div className="legend">{iconGenerator(3)}</div>}
      {!isNSONE(props.envSchema.id) && !isSCIS(props.envSchema.solution) && !isEIS(props.envSchema.solution) && (
        <React.Fragment>
          <div className="legend">{iconGenerator(4)}</div>
          <div className="legendNA">{iconGenerator(5)}</div>
        </React.Fragment>
      )}
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema
  }), {
}
)(HealthStatusLegend);