import React from 'react';
import { connect } from 'react-redux';

import { isManualMonitoring } from '../Helper/ComponentHelper';
import { StructuredListBody, StructuredListCell, StructuredListHead, StructuredListRow, StructuredListWrapper } from 'carbon-components-react';
import HealthStatusHeaderCellsRow from './HealthStatusHeaderCellsRow';
import HealthStatusMonitorTooltip from './HealthStatusMonitorTooltip';
import HealthStatusMonitor from './HealthStatusMonitor';
import { isNSONE } from '../Helper/ComponentHelper';

const HealthStatusMonitorList = (props) => {
  const createContent = () => {
    let content = [];

    props.serviceByCategoryMapArray[props.selectedTabIndex].forEach((monitorMap, key) => {
      if (!isManualMonitoring(props.envSchema.solution)) {
        content.push(<HealthStatusMonitorTooltip monitorMap={monitorMap} key={key} label={'Monitors'} />);
      }
      if (props.monitorData) {
        content.push(createStructuredListBodyRowGenerator(monitorMap));
      }
    });

    return content;
  }

  const createStructuredListBodyRowGenerator = (monitorMap) => {
    return monitorMap.map((monitor, i) => (
      <HealthStatusMonitor
        name={monitor.name}
        index={i}
        key={i}
        monitorValues={filterMonitorMap(monitor.monitorMap)}
        data={determineCorrectDataForMonitor(monitor.id)}
        description={monitor.description}
        appSpecText={createMonitorSpecDescription(getAppSpec(monitor.monitorMap, "maximo"), getAppSpec(monitor.monitorMap, "tririga"))} />
    ));
  }

  const filterMonitorMap = (monitorMap) => {
    let monitorValues = [];
    let orderedAndFilteredMonitorValues = [];
    let datacenterNames = props.datacentersInfoArray[props.selectedTabIndex].map((datacenter) => datacenter.id);

    monitorMap.forEach((monitor, key) => {
      if (datacenterNames.includes(key)) {
        monitorValues.push({ key: key, status: monitor.id, enabled: monitor.enabled });
      }
    });

    datacenterNames.forEach(function (key) {
      let found = false;
      monitorValues = monitorValues.filter(function (monitorValue) {
        if (!found && monitorValue.key === key) {
          orderedAndFilteredMonitorValues.push(monitorValue);
          found = true;
          return false;
        } else
          return true;
      })
    })

    return orderedAndFilteredMonitorValues;
  }

  const determineCorrectDataForMonitor = (id) => {
    let correctData = [];

    props.monitorData.forEach((arrayOfData) => {
      if (Array.isArray(arrayOfData)) {
        arrayOfData.forEach((data) => {
          if (data.service === id) {
            correctData.push(data);
          }
        });
      }
    });

    return correctData;
  }

  const createMonitorSpecDescription = (maximoAppSpec, tririgaAppSpec) => {
    let text = [];

    if (maximoAppSpec) {
      text.push(
        <div key={0}>
          <p className="tooltipTextDescription">
            MAXIMO APP VERSIONS:
          </p>
          {renderMaximoSpecVersionText(maximoAppSpec)}
        </div>);
    }
    if (tririgaAppSpec) {
      text.push(
        <div key={1}>
          <p className="tooltipTextDescription">
            TRIRIGA APP VERSIONS:
          </p>
          {renderTririgaSpecVersionText(tririgaAppSpec)}
        </div>);
    }

    return text;
  }

  const getAppSpec = (monitorMap, specType) => {
    let datacenterNames = props.datacentersInfoArray[props.selectedTabIndex].map((datacenter) => datacenter.id);
    let result = [];

    monitorMap.forEach((monitor, key) => {
      if (datacenterNames.includes(key) && monitor.status !== 'dummy' && monitor.id !== 'dummy') {
        result.push(monitor);
      }
    });

    return specType === 'tririga' ? result[0].tririgaAppSpec : result[0].maximoAppSpec;
  }

  const renderMaximoSpecVersionText = (specs) => {
    let arrayOfCompleteMaximoVersionInfo = [];
    let arrayOfBasicMaximoVersionInfo = [];

    Object.entries(specs).forEach(([key, value]) => {
      if (key === 'MAX_VER_SH_KEY') {
        arrayOfCompleteMaximoVersionInfo.push(...value.split(', '));  // Parse complete version info
      } else {
        arrayOfBasicMaximoVersionInfo.push(value);
      }
    });

    if (arrayOfCompleteMaximoVersionInfo.length <= 0) {
      // If there's no complete version info, display basic version info
      return arrayOfBasicMaximoVersionInfo.map(item => {
        return (
          <p
            key={item}
            className="tooltipTextURL">
            {item}
          </p>
        );
      })
    }

    arrayOfCompleteMaximoVersionInfo = arrayOfCompleteMaximoVersionInfo.map(item => {
      return (
        <React.Fragment>
          <p
            key={item}
            className="tooltipTextURL">
            {item}
          </p>
          <br>
          </br>
        </React.Fragment>
      );
    })

    return arrayOfCompleteMaximoVersionInfo;
  }

  const renderTririgaSpecVersionText = (specs) => {
    const arrayOfItems = Object.entries(specs).map(([key, value]) => {
      return key === 'APPLICATION_VERSION' ?
        (<p
          key={key}
          className="tooltipTextURL">
          Application Version: {value}
        </p>) :
        (<p
          key={key}
          className="tooltipTextURL">
          Platform Version: {value}
        </p>)
    });

    return arrayOfItems;
  }

  return (
    <StructuredListWrapper selection border className="healthStatus">
      <StructuredListHead>
        <StructuredListRow head className="desktopOnly">
          <StructuredListCell head className="dataCenterHeadCell">
          {isNSONE(props.envSchema.id)
            ? "Services"
            : isManualMonitoring(props.envSchema.solution)
            ? "Applications"
            : "Data Center"}
          </StructuredListCell>
          <HealthStatusHeaderCellsRow isDesktop={true} />
        </StructuredListRow>
        <StructuredListRow head className="mobileOnly">
          <StructuredListCell head className="dataCenterHeadCell">
          {isNSONE(props.envSchema.id)
            ? "Services"
            : isManualMonitoring(props.envSchema.solution)
            ? "Applications"
            : "Data Center"}
          </StructuredListCell>
          <HealthStatusHeaderCellsRow isDesktop={false} />
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {createContent()}
      </StructuredListBody>
    </StructuredListWrapper>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    selectedTabIndex: state.healthStatus.selectedTabIndex,
    monitorData: state.healthStatus.monitorData,
    datacentersInfoArray: state.healthStatus.datacentersInfoArray,
    serviceByCategoryMapArray: state.healthStatus.serviceByCategoryMapArray
  }), {
}
)(HealthStatusMonitorList);