import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from './BaseReducer';
import { fetchNotifications, fetchNotificationSubCategories } from '../lib/NotificationDataTableServices';
import { returnLocaleTimeZoneString } from '../components/Helper/TimeHelper';
import { sortAlerts, sortNotifications } from '../components/Communications/CommunicationsHelper';

const moment = require('moment-timezone');

const initState = {
  rawNotifications: [],
  rawAlerts: [],
  displayNotifications: [],
  displayAlerts: [],
  filteredNotifications: [],
  filteredAlerts: [],
  bannerNotification: null,
  filteredByTextNotifications: null,
  filteredByTextAlerts: null,
  filters: {},
  alertFilters: {},
  pageNumber: 1,
  pageSize: 10,
  alertPageNumber: 1,
  alertPageSize: 10,
  filterAreaOpened: false,
  alertFilterAreaOpened: false,
  initialDateFilter: '',
  initialTimeFilter: '',
  finalDateFilter: '',
  finalTimeFilter: '',
  alertInitialDateFilter: '',
  alertInitialTimeFilter: '',
  alertFinalDateFilter: '',
  alertFinalTimeFilter: '',
  searchValue: '',
  alertSearchValue: '',
  categoryFiltersControl: Array(4).fill(false),
  statusFiltersControl: Array(4).fill(false),
  alertStatusFiltersControl: Array(4).fill(false),
  notificationSubCategories: [],
  subCategoryFiltersControl: [],
  isRegionsEmpty: true,
  isCommunicationsCalendarModalOpen: false,
  isDownloadCommunicationsModalOpen: false,
  notificationIdFilter: '',
  loadNotificationStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  fetchNotificationSubCategoriesStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

export const NOTIFICATION_DATA_TABLE_FILTER_NOTIFICATIONS_BY_TEXT = 'NOTIFICATION_DATA_TABLE_FILTER_NOTIFICATIONS_BY_TEXT';
export const NOTIFICATION_DATA_TABLE_FILTER_ALERTS_BY_TEXT = 'NOTIFICATION_DATA_TABLE_FILTER_ALERTS_BY_TEXT';

const NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS = 'NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS';
export const loadNotificationsAction = (notifications) => ({ type: NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS, payload: notifications });

const ALERTS_DATA_TABLE_LOAD_ALERTS = 'ALERTS_DATA_TABLE_LOAD_ALERTS';
export const loadAlertsAction = (alerts) => ({ type: ALERTS_DATA_TABLE_LOAD_ALERTS, payload: alerts });

const NOTIFICATION_DATA_TABLE_LOAD_BANNER = 'NOTIFICATION_DATA_TABLE_LOAD_BANNER';
export const loadBannerAction = (newBanner) => ({ type: NOTIFICATION_DATA_TABLE_LOAD_BANNER, payload: newBanner });

const NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS_FILTERED = 'NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS_FILTERED';
export const loadNotificationsFilteredAction = (notificationsFiltered) => ({ type: NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS_FILTERED, payload: notificationsFiltered });

const NOTIFICATION_DATA_TABLE_LOAD_ALERTS_FILTERED = 'NOTIFICATION_DATA_TABLE_LOAD_ALERTS_FILTERED';
export const loadAlertsFilteredAction = (alertsFiltered) => ({ type: NOTIFICATION_DATA_TABLE_LOAD_ALERTS_FILTERED, payload: alertsFiltered });

const NOTIFICATION_DATA_TABLE_UPDATE_FILTERS = 'NOTIFICATION_DATA_TABLE_UPDATE_FILTERS';
export const updateFiltersAction = (newFilters) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_FILTERS, payload: newFilters });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FILTERS = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FILTERS';
export const updateAlertFiltersAction = (newAlertFilters) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FILTERS, payload: newAlertFilters });

const NOTIFICATION_DATA_TABLE_UPDATE_PAGE_NUMBER = 'NOTIFICATION_DATA_TABLE_UPDATE_PAGE_NUMBER';
export const updatePageDataAction = (newPageData) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_PAGE_NUMBER, payload: newPageData });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_PAGE_NUMBER = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_PAGE_NUMBER';
export const updateAlertPageDataAction = (newAlertPageData) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_PAGE_NUMBER, payload: newAlertPageData });

const NOTIFICATION_DATA_TABLE_TOGGLE_FILTER_AREA_OPENED = 'NOTIFICATION_DATA_TABLE_TOGGLE_FILTER_AREA_OPENED';
export const toggleFilterAreaOpenedAction = () => ({ type: NOTIFICATION_DATA_TABLE_TOGGLE_FILTER_AREA_OPENED, payload: '' });

const NOTIFICATION_DATA_TABLE_TOGGLE_ALERT_FILTER_AREA_OPENED = 'NOTIFICATION_DATA_TABLE_TOGGLE_ALERT_FILTER_AREA_OPENED';
export const toggleAlertFilterAreaOpenedAction = () => ({ type: NOTIFICATION_DATA_TABLE_TOGGLE_ALERT_FILTER_AREA_OPENED, payload: '' });

const NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_DATE_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_DATE_FILTER';
export const updateInitialDateFilterAction = (newInitialFilterDate) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_DATE_FILTER, payload: newInitialFilterDate });

const NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_TIME_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_TIME_FILTER';
export const updateInitialTimeFilterAction = (newInitialFilterTime) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_TIME_FILTER, payload: newInitialFilterTime });

const NOTIFICATION_DATA_TABLE_UPDATE_FINAL_DATE_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_FINAL_DATE_FILTER';
export const updateFinalDateFilterAction = (newFinalFilterDate) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_FINAL_DATE_FILTER, payload: newFinalFilterDate });

const NOTIFICATION_DATA_TABLE_UPDATE_FINAL_TIME_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_FINAL_TIME_FILTER';
export const updateFinalTimeFilterAction = (newFinalFilterTime) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_FINAL_TIME_FILTER, payload: newFinalFilterTime });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_DATE_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_DATE_FILTER';
export const updateAlertInitialDateFilterAction = (newInitialFilterDate) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_DATE_FILTER, payload: newInitialFilterDate });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_TIME_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_TIME_FILTER';
export const updateAlertInitialTimeFilterAction = (newInitialFilterTime) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_TIME_FILTER, payload: newInitialFilterTime });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_DATE_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_DATE_FILTER';
export const updateAlertFinalDateFilterAction = (newFinalFilterDate) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_DATE_FILTER, payload: newFinalFilterDate });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_TIME_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_TIME_FILTER';
export const updateAlertFinalTimeFilterAction = (newFinalFilterTime) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_TIME_FILTER, payload: newFinalFilterTime });

const NOTIFICATION_DATA_TABLE_UPDATE_SEARCH_VALUE = 'NOTIFICATION_DATA_TABLE_UPDATE_SEARCH_VALUE';
export const updateSearchValueAction = (newSearchValue) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_SEARCH_VALUE, payload: newSearchValue });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_SEARCH_VALUE = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_SEARCH_VALUE';
export const updateAlertSearchValueAction = (newAlertSearchValue) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_SEARCH_VALUE, payload: newAlertSearchValue });

const NOTIFICATION_DATA_TABLE_UPDATE_SUBCATEGORY_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_UPDATE_SUBCATEGORY_FILTERS_CONTROL';
export const updateSubCategoryFiltersControlAction = (parentCategory, subCategory, id) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_SUBCATEGORY_FILTERS_CONTROL, parentCategory: parentCategory, subCategory: subCategory, id: id });

const NOTIFICATION_DATA_TABLE_RESET_SUBCATEGORY_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_RESET_SUBCATEGORY_FILTERS_CONTROL';
export const resetSubCategoriesFiltersControlAction = () => ({ type: NOTIFICATION_DATA_TABLE_RESET_SUBCATEGORY_FILTERS_CONTROL, payload: '' });

const NOTIFICATION_DATA_TABLE_UPDATE_CATEGORY_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_UPDATE_CATEGORY_FILTERS_CONTROL';
export const updateCategoryFiltersControlAction = (indexChanged) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_CATEGORY_FILTERS_CONTROL, payload: indexChanged });

const NOTIFICATION_DATA_TABLE_RESET_CATEGORY_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_RESET_CATEGORY_FILTERS_CONTROL';
export const resetCategoryFiltersControlAction = () => ({ type: NOTIFICATION_DATA_TABLE_RESET_CATEGORY_FILTERS_CONTROL, payload: '' });

const NOTIFICATION_DATA_TABLE_UPDATE_STATUS_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_UPDATE_STATUS_FILTERS_CONTROL';
export const updateStatusFiltersControlAction = (indexChanged) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_STATUS_FILTERS_CONTROL, payload: indexChanged });

const NOTIFICATION_DATA_TABLE_RESET_STATUS_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_RESET_STATUS_FILTERS_CONTROL';
export const resetStatusFiltersControlAction = () => ({ type: NOTIFICATION_DATA_TABLE_RESET_STATUS_FILTERS_CONTROL, payload: '' });

const NOTIFICATION_DATA_TABLE_UPDATE_ALERT_STATUS_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_UPDATE_ALERT_STATUS_FILTERS_CONTROL';
export const updateAlertStatusFiltersControlAction = (indexChanged) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_ALERT_STATUS_FILTERS_CONTROL, payload: indexChanged });

const NOTIFICATION_DATA_TABLE_RESET_ALERT_STATUS_FILTERS_CONTROL = 'NOTIFICATION_DATA_TABLE_RESET_ALERT_STATUS_FILTERS_CONTROL';
export const resetAlertStatusFiltersControlAction = () => ({ type: NOTIFICATION_DATA_TABLE_RESET_ALERT_STATUS_FILTERS_CONTROL, payload: '' });

const NOTIFICATION_DATA_TABLE_UPDATE_IS_REGIONS_EMPTY = 'NOTIFICATION_DATA_TABLE_UPDATE_IS_REGIONS_EMPTY';
export const updateIsRegionsEmptyAction = (newValue) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_IS_REGIONS_EMPTY, payload: newValue });

const NOTIFICATION_DATA_TABLE_UPDATE_LOAD_STATUS = 'NOTIFICATION_DATA_TABLE_UPDATE_LOAD_STATUS';
export const updateLoadStatusAction = (newStatus) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_LOAD_STATUS, payload: newStatus });

const NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_ID_FILTER = 'NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_ID_FILTER';
export const updateNotificationIdFilterAction = (notificationIdFilter) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_ID_FILTER, payload: notificationIdFilter });

const NOTIFICATION_DATA_TABLE_UPDATE_IS_COMMUNICATIONS_CALENDAR_MODAL_OPEN = 'NOTIFICATION_DATA_TABLE_UPDATE_IS_COMMUNICATIONS_CALENDAR_MODAL_OPEN';
export const updateIsCommunicationsCalendarModalOpenAction = (value) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_IS_COMMUNICATIONS_CALENDAR_MODAL_OPEN, payload: value });

const NOTIFICATION_DATA_TABLE_UPDATE_IS_DOWNLOAD_COMMUNICATIONS_MODAL_OPEN = 'NOTIFICATION_DATA_TABLE_UPDATE_IS_DOWNLOAD_COMMUNICATIONS_MODAL_OPEN';
export const updateIsDownloadCommunicationsModalOpenAction = (value) => ({ type: NOTIFICATION_DATA_TABLE_UPDATE_IS_DOWNLOAD_COMMUNICATIONS_MODAL_OPEN, payload: value });

const NOTIFICATION_DATA_TABLE_UPDATE_FETCH_NOTIFICATION_SUB_CATEGORIES_STATUS = 'NOTIFICATION_DATA_TABLE_UPDATE_FETCH_NOTIFICATION_SUB_CATEGORIES_STATUS';
export const updateFetchNotificationSubCategoriesStatusAction = (newStatus) => ({
  type: NOTIFICATION_DATA_TABLE_UPDATE_FETCH_NOTIFICATION_SUB_CATEGORIES_STATUS,
  payload: newStatus
})

const NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_SUB_CATEGORIES = 'NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_SUB_CATEGORIES';
export const updateNotificationSubCategoriesAction = (newValue) => ({
  type: NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_SUB_CATEGORIES,
  payload: newValue
})

export const loadNotifications = (environmentId, serviceType) => {
  return (dispatch) => {
    dispatch(updateLoadStatusAction(SUBMISSION_STATUS_SUBMITTED));

    fetchNotifications(environmentId, serviceType)
      .then(data => {
        let nonAlertsNotifications = data.filter(notification => notification.category !== 'Alert' && notification.category !== 'Banner');
        let bannerNotification = data.filter(notification => notification.category === 'Banner')[0];
        let alertNotifications = data.filter(notification => notification.category === 'Alert');
        let isRegionsEmpty = data.filter(notification => notification.instance !== 'all').length === 0;

        nonAlertsNotifications.map(notification => {
          notification.createdDate = notification.createdDate ? returnLocaleTimeZoneString(notification.createdDate) : '';
          notification.date = notification.date ? returnLocaleTimeZoneString(notification.date) : '';
          notification.updateDate = notification.updateDate ? returnLocaleTimeZoneString(notification.updateDate) : '';
          notification.announcementEventStartTime = notification.announcementEventStartTime ? returnLocaleTimeZoneString(notification.announcementEventStartTime) : '';
          notification.announcementEventEndTime = notification.announcementEventEndTime ? returnLocaleTimeZoneString(notification.announcementEventEndTime) : '';

          notification.realDate = notification.status === "published" ? notification.date : notification.updateDate;

          //TODO: See if this is necessary
          if (notification.realDate === "" && notification.date !== "") {
            notification.realDate = notification.date;
          }
        });

        nonAlertsNotifications = nonAlertsNotifications.sort(sortNotifications);

        alertNotifications.map((alert) => {
          alert.createdDate = alert.createdDate ? returnLocaleTimeZoneString(alert.createdDate) : '';
          alert.date = alert.date ? returnLocaleTimeZoneString(alert.date) : '';
          alert.updateDate = alert.updateDate ? returnLocaleTimeZoneString(alert.updateDate) : '';
          alert.announcementEventStartTime = alert.announcementEventStartTime ? returnLocaleTimeZoneString(alert.announcementEventStartTime) : '';
          alert.announcementEventEndTime = alert.announcementEventEndTime ? returnLocaleTimeZoneString(alert.announcementEventEndTime) : '';

          alert.realDate = alert.status === "published" ? alert.date : alert.updateDate;

          //TODO: See if this is necessary
          if (alert.realDate === "" && alert.date !== "") {
            alert.realDate = alert.date;
          }
        });

        alertNotifications = alertNotifications.sort(sortAlerts);

        if (bannerNotification) {
          dispatch(loadBannerAction(bannerNotification));
        }

        dispatch(updateLoadStatusAction(SUBMISSION_STATUS_SUCCESS));

        dispatch(updateIsRegionsEmptyAction(isRegionsEmpty));

        dispatch(loadNotificationsAction(nonAlertsNotifications));

        dispatch(loadAlertsAction(alertNotifications));
      })
      .catch(error => {
        dispatch(updateLoadStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadNotificationsAction([]));
      });
  }
}

const executeFilter = (filters, notificationsToBeFiltered) => {
  let filteredNotifications;
  let initialDateFilter, finalDateFilter;

  if (filters.initialFilterData) {
    initialDateFilter = moment.tz(filters.initialFilterData, 'UTC');
  }

  if (filters.finalFilterData) {
    finalDateFilter = moment.tz(filters.finalFilterData, 'UTC');
  }

  filteredNotifications = notificationsToBeFiltered.filter((notification) => {
    let dateFilter = true;
    let categoryFilter = true;
    let subCategoryFilter = true;
    let statusFilter = true;
    let selectedNotificationFilter = true;

    if (initialDateFilter && !finalDateFilter) {
      dateFilter = (moment(notification.createdDate).diff(initialDateFilter) >= 0) ||
        (notification.updateDate ? moment(notification.updateDate).diff(initialDateFilter) >= 0 : false) ||
        (notification.announcementEventStartTime ? moment(notification.announcementEventStartTime).diff(initialDateFilter) >= 0 : false);
    } else if (!initialDateFilter && finalDateFilter) {
      dateFilter = (moment(notification.createdDate).diff(finalDateFilter) <= 0) ||
        (notification.updateDate ? moment(notification.updateDate).diff(finalDateFilter) <= 0 : false) ||
        (notification.announcementEventEndTime ? moment(notification.announcementEventEndTime).diff(finalDateFilter) <= 0 : false);
    } else if (initialDateFilter && finalDateFilter) {
      dateFilter = (moment(notification.createdDate).diff(initialDateFilter) >= 0 && moment(notification.createdDate).diff(finalDateFilter) <= 0) ||
        (notification.updateDate ? moment(notification.updateDate).diff(initialDateFilter) >= 0 && moment(notification.updateDate).diff(finalDateFilter) <= 0 : false) ||
        (notification.announcementEventStartTime ? moment(notification.announcementEventStartTime).diff(initialDateFilter) >= 0 && moment(notification.announcementEventEndTime).diff(finalDateFilter) <= 0 : false);
    }

    categoryFilter = filters.categoriesAllowed ? filters.categoriesAllowed.includes(notification.category) : true;

    if (filters.isSubCategorySelected) {
      subCategoryFilter = filters.subCategoriesAllowed ? filters.subCategoriesAllowed.includes(notification.subCategory) : true;
    }

    statusFilter = filters.statusAllowed ? filters.statusAllowed.includes(notification.status) : true;

    selectedNotificationFilter = filters.selectedNotificationId ? filters.selectedNotificationId.includes(notification.id) : true;

    return dateFilter && subCategoryFilter && categoryFilter && statusFilter && selectedNotificationFilter;
  });

  return filteredNotifications;
}

export const filterNotifications = (filters) => {
  return (dispatch, getState) => {
    let newFilteredNotifications = executeFilter(filters, getState().communications.filteredByTextNotifications ?
      getState().communications.filteredByTextNotifications : getState().communications.rawNotifications)

    dispatch(updateFiltersAction(filters));
    dispatch(loadNotificationsFilteredAction(newFilteredNotifications));
  }
}

export const filterAlerts = (filters) => {
  return (dispatch, getState) => {
    let newFilteredAlerts = executeFilter(filters, getState().communications.filteredByTextAlerts ?
      getState().communications.filteredByTextAlerts : getState().communications.rawAlerts)

    dispatch(updateAlertFiltersAction(filters));
    dispatch(loadAlertsFilteredAction(newFilteredAlerts));
  }
}

export const clearSubcategoriesFilters = () => {
  return (dispatch) => {
    dispatch(resetSubCategoriesFiltersControlAction());
  }
}

export const clearFilters = () => {
  return (dispatch, getState) => {
    let originalNotifications = getState().communications.filteredByTextNotifications ?
      getState().communications.filteredByTextNotifications : getState().communications.rawNotifications;

    dispatch(loadNotificationsFilteredAction(originalNotifications));
    dispatch(updateInitialDateFilterAction(''));
    dispatch(updateInitialTimeFilterAction(''));
    dispatch(updateFinalDateFilterAction(''));
    dispatch(updateFinalTimeFilterAction(''));
    dispatch(resetCategoryFiltersControlAction());
    dispatch(resetSubCategoriesFiltersControlAction());
    dispatch(resetStatusFiltersControlAction());
  }
}

export const clearAlertFilters = () => {
  return (dispatch, getState) => {
    let originalAlerts = getState().communications.filteredByTextAlerts ?
      getState().communications.filteredByTextAlerts : getState().communications.rawAlerts;

    dispatch(loadAlertsAction(originalAlerts));
    dispatch(updateAlertInitialDateFilterAction(''));
    dispatch(updateAlertInitialTimeFilterAction(''));
    dispatch(updateAlertFinalDateFilterAction(''));
    dispatch(updateAlertFinalTimeFilterAction(''));
    dispatch(resetCategoryFiltersControlAction());
    dispatch(resetSubCategoriesFiltersControlAction());
    dispatch(resetAlertStatusFiltersControlAction());
    dispatch(updateNotificationIdFilter(''));
  }
}

export const updatePageData = (pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch(updatePageDataAction({ pageNumber: pageNumber, pageSize: pageSize }));
  }
}

export const updateAlertPageData = (pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch(updateAlertPageDataAction({ pageNumber: pageNumber, pageSize: pageSize }));
  }
}

export const toggleFilterAreaOpened = () => {
  return (dispatch) => {
    dispatch(toggleFilterAreaOpenedAction());
  }
}

export const toggleAlertFilterAreaOpened = () => {
  return (dispatch) => {
    dispatch(toggleAlertFilterAreaOpenedAction());
  }
}

export const updateInitialDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateInitialDateFilterAction(value));
  }
}

export const updateInitialTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateInitialTimeFilterAction(value));
  }
}

export const updateFinalDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateFinalDateFilterAction(value));
  }
}

export const updateFinalTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateFinalTimeFilterAction(value));
  }
}

export const updateAlertInitialDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateAlertInitialDateFilterAction(value));
  }
}

export const updateAlertInitialTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateAlertInitialTimeFilterAction(value));
  }
}

export const updateAlertFinalDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateAlertFinalDateFilterAction(value));
  }
}

export const updateAlertFinalTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateAlertFinalTimeFilterAction(value));
  }
}

export const updateSearchValue = (value) => {
  return (dispatch) => {
    dispatch(updateSearchValueAction(value));
  }
}

export const updateAlertSearchValue = (value) => {
  return (dispatch) => {
    dispatch(updateAlertSearchValueAction(value));
  }
}

export const updateCategoryFiltersControl = (index) => {
  return (dispatch) => {
    dispatch(updateCategoryFiltersControlAction(index));
  }
}

export const updateSubCategoryFiltersControl = (parentCategory, subCategory, id) => {
  return (dispatch) => {
    dispatch(updateSubCategoryFiltersControlAction(parentCategory, subCategory, id));
  }
}

export const updateStatusFiltersControl = (index) => {
  return (dispatch) => {
    dispatch(updateStatusFiltersControlAction(index));
  }
}

export const updateAlertStatusFiltersControl = (index) => {
  return (dispatch) => {
    dispatch(updateAlertStatusFiltersControlAction(index));
  }
}

export const updateNotificationIdFilter = (value) => {
  return (dispatch) => {
    dispatch(updateNotificationIdFilterAction(value));
  }
}

const requestNotificationSubCategoriesDataTableReload = (dispatch, solutionId) => {
  dispatch(updateFetchNotificationSubCategoriesStatusAction(SUBMISSION_STATUS_SUBMITTED));

  fetchNotificationSubCategories(solutionId)
    .then(data => {
      if (Array.isArray(data) && data[0].subCategory) {
        dispatch(updateNotificationSubCategoriesAction(data));
        dispatch(updateFetchNotificationSubCategoriesStatusAction(SUBMISSION_STATUS_SUCCESS));
      } else {
        dispatch(updateNotificationSubCategoriesAction([]));
        dispatch(updateFetchNotificationSubCategoriesStatusAction(SUBMISSION_STATUS_FAILED));
      }
    })
    .catch(error => {
      dispatch(updateNotificationSubCategoriesAction([]));
      dispatch(updateFetchNotificationSubCategoriesStatusAction(SUBMISSION_STATUS_FAILED));
    })
}

export const requestNotificationSubCategories = (solutionId) => {
  return (dispatch) => {
    requestNotificationSubCategoriesDataTableReload(dispatch, solutionId)
  }
}

export const updateIsCommunicationsCalendarModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateIsCommunicationsCalendarModalOpenAction(value));
  }
}

export const updateIsDownloadCommunicationsModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateIsDownloadCommunicationsModalOpenAction(value));
  }
}

export const resetNotificationSubCategoriesStatus = () => {
  return (dispatch) => {
    dispatch(updateFetchNotificationSubCategoriesStatusAction(SUBMISSION_STATUS_INITIAL_STATUS));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS:
      return {
        ...state,
        rawNotifications: action.payload,
        filteredNotifications: action.payload,
        displayNotifications: action.payload,
        filteredByTextNotifications: null
      }

    case ALERTS_DATA_TABLE_LOAD_ALERTS:
      return {
        ...state,
        rawAlerts: action.payload,
        filteredAlerts: action.payload,
        displayAlerts: action.payload,
        filteredByTextAlerts: null
      }

    case NOTIFICATION_DATA_TABLE_LOAD_BANNER:
      return {
        ...state,
        bannerNotification: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_LOAD_STATUS:
      return {
        ...state,
        loadNotificationStatus: action.payload
      }

    case NOTIFICATION_DATA_TABLE_LOAD_NOTIFICATIONS_FILTERED:
      return {
        ...state,
        filteredNotifications: action.payload,
        displayNotifications: action.payload
      }

    case NOTIFICATION_DATA_TABLE_LOAD_ALERTS_FILTERED:
      return {
        ...state,
        filteredAlerts: action.payload,
        displayAlerts: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_PAGE_NUMBER:
      return {
        ...state,
        alertPageNumber: action.payload.pageNumber,
        alertPageSize: action.payload.pageSize
      }

    case NOTIFICATION_DATA_TABLE_TOGGLE_FILTER_AREA_OPENED:
      return {
        ...state,
        filterAreaOpened: !state.filterAreaOpened
      }

    case NOTIFICATION_DATA_TABLE_TOGGLE_ALERT_FILTER_AREA_OPENED:
      return {
        ...state,
        alertFilterAreaOpened: !state.alertFilterAreaOpened
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_DATE_FILTER:
      return {
        ...state,
        initialDateFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_INITIAL_TIME_FILTER:
      return {
        ...state,
        initialTimeFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_FINAL_DATE_FILTER:
      return {
        ...state,
        finalDateFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_FINAL_TIME_FILTER:
      return {
        ...state,
        finalTimeFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_DATE_FILTER:
      return {
        ...state,
        alertInitialDateFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_INITIAL_TIME_FILTER:
      return {
        ...state,
        alertInitialTimeFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_DATE_FILTER:
      return {
        ...state,
        alertFinalDateFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_FINAL_TIME_FILTER:
      return {
        ...state,
        alertFinalTimeFilter: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_SEARCH_VALUE:
      return {
        ...state,
        alertSearchValue: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_SUBCATEGORY_FILTERS_CONTROL:
      let newSubCategoryControl = [...state.subCategoryFiltersControl];

      newSubCategoryControl = newSubCategoryControl.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            parentCategory: action.parentCategory,
            subCategory: action.subCategory,
            id: action.id,
            index: !item.index
          };
        }
        return item;
      });

      return {
        ...state,
        subCategoryFiltersControl: newSubCategoryControl
      }

    case NOTIFICATION_DATA_TABLE_RESET_SUBCATEGORY_FILTERS_CONTROL:
      const selectedCategories = Object.keys(state.categoryFiltersControl)
        .filter(key => state.categoryFiltersControl[key])
        .map(key => ["Announcement", "Maintenance", "Incident", "Security", 'Banner'][key]);

      let isAnyIndexTrue = Object.values(state.subCategoryFiltersControl).some(filter => filter.index);
      let SubCategoryControl = state.notificationSubCategories.map(item => {
        let isSelectedCategory = selectedCategories.includes(item.parentCategory);
        let existingFilter = Object.values(state.subCategoryFiltersControl)
          .find(filter => filter.id === item.id) || false;

        return {
          parentCategory: item.parentCategory,
          subCategory: item.subCategory,
          id: item.id,
          index: isAnyIndexTrue && existingFilter ? isSelectedCategory ? existingFilter.index : false : false
        };
      });

      return {
        ...state,
        subCategoryFiltersControl: SubCategoryControl
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_CATEGORY_FILTERS_CONTROL:
      const newCategoryControl = [...state.categoryFiltersControl];

      newCategoryControl[action.payload] = !newCategoryControl[action.payload];

      return {
        ...state,
        categoryFiltersControl: newCategoryControl
      }

    case NOTIFICATION_DATA_TABLE_RESET_CATEGORY_FILTERS_CONTROL:
      return {
        ...state,
        categoryFiltersControl: new Array(4).fill(false)
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_STATUS_FILTERS_CONTROL:
      let statusFiltersControl = [...state.statusFiltersControl];

      statusFiltersControl[action.payload] = !statusFiltersControl[action.payload];

      return {
        ...state,
        statusFiltersControl: statusFiltersControl
      }

    case NOTIFICATION_DATA_TABLE_RESET_STATUS_FILTERS_CONTROL:
      return {
        ...state,
        statusFiltersControl: new Array(4).fill(false)
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_ALERT_STATUS_FILTERS_CONTROL:
      let alertStatusFiltersControl = [...state.alertStatusFiltersControl];

      alertStatusFiltersControl[action.payload] = !alertStatusFiltersControl[action.payload];

      return {
        ...state,
        alertStatusFiltersControl: alertStatusFiltersControl
      }

    case NOTIFICATION_DATA_TABLE_RESET_ALERT_STATUS_FILTERS_CONTROL:
      return {
        ...state,
        alertStatusFiltersControl: new Array(4).fill(false)
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_IS_REGIONS_EMPTY:
      return {
        ...state,
        isRegionsEmpty: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_FETCH_NOTIFICATION_SUB_CATEGORIES_STATUS:
      return {
        ...state,
        fetchNotificationSubCategoriesStatus: action.payload
      }

    case NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_SUB_CATEGORIES:
      return {
        ...state,
        notificationSubCategories: action.payload,
        filteredNotificationSubCategories: null
      }

    case NOTIFICATION_DATA_TABLE_FILTER_NOTIFICATIONS_BY_TEXT:
      let payload = action.payload;
      let text = action.text;

      if (payload && text) {
        let filteredByTextNotifications = state.filteredNotifications.filter((notification) => payload.has(notification.id));
        return { ...state, filteredByTextNotifications: filteredByTextNotifications, displayNotifications: filteredByTextNotifications };
      }

      let reFilteredNotifications = executeFilter(state.filters, state.rawNotifications);
      return { ...state, filteredByTextNotifications: null, displayNotifications: reFilteredNotifications };

    case NOTIFICATION_DATA_TABLE_FILTER_ALERTS_BY_TEXT:
      let payloadAlert = action.payload;
      let textAlert = action.text;

      if (payloadAlert && textAlert) {
        let filteredByTextAlerts = state.filteredAlerts.filter((alert) => payloadAlert.has(alert.id));
        return { ...state, filteredByTextAlerts: filteredByTextAlerts, displayAlerts: filteredByTextAlerts };
      }

      let reFilteredAlerts = executeFilter(state.alertFilters, state.rawAlerts);
      return { ...state, filteredByTextAlerts: null, displayAlerts: reFilteredAlerts };

    case NOTIFICATION_DATA_TABLE_UPDATE_NOTIFICATION_ID_FILTER:
      return { ...state, notificationIdFilter: action.payload }

    case NOTIFICATION_DATA_TABLE_UPDATE_IS_COMMUNICATIONS_CALENDAR_MODAL_OPEN:
      return { ...state, isCommunicationsCalendarModalOpen: action.payload }

    case NOTIFICATION_DATA_TABLE_UPDATE_IS_DOWNLOAD_COMMUNICATIONS_MODAL_OPEN:
      return { ...state, isDownloadCommunicationsModalOpen: action.payload }

    default:
      return state
  }
}