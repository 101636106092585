export const DOWNLOAD_HEADERS = [
  {
    key: 'id',
    label: 'Notification ID',
  }, {
    key: 'status',
    label: 'Status'
  }, {
    key: 'category',
    label: 'Category'
  }, {
    key: 'subCategory',
    label: 'Category'
  }, {
    key: 'announcementEventStartTime',
    label: 'Start Time'
  }, {
    key: 'announcementEventEndTime',
    label: 'End Time'
  }, {
    key: 'title',
    label: 'Title'
  }, {
    key: 'body',
    label: 'Message'
  }, {
    key: 'date',
    label: 'Publish Date'
  }, {
    key: 'updateDate',
    label: 'Update Date'
  }
];

export const NOTIFICATION_HEADERS = [{
  header: 'Title',
  key: 'title'
},
{
  header: 'Body',
  key: 'body'
},
{
  header: 'Date',
  key: 'realDate'
},
{
  header: 'publishedDate',
  key: 'date'
},
{
  header: 'createdDate',
  key: 'createdDate'
},
{
  header: 'UpdatedDate',
  key: 'updateDate'
},
{
  header: 'Status',
  key: 'status'
},
{
  header: 'Category',
  key: 'category'
},
{
  header: 'SubCategory',
  key: 'subCategory'
},
{
  header: 'enabledEventTimeRange',
  key: 'enabledEventTimeRange'
},
{
  header: 'announcementEventStartTime',
  key: 'announcementEventStartTime'
},
{
  header: 'announcementEventEndTime',
  key: 'announcementEventEndTime'
},
{
  header: 'Regions',
  key: 'instance'
},
{
  header: 'monitorControlArray',
  key: 'monitorControlArray'
},
{
  header: 'subscriptionCategories',
  key: 'subscriptionCategories'
}];

export const checkCommunicationsHeader = (headerKey, isMobile) => {
  if (!isMobile) {
    return (headerKey.includes('title')
      || headerKey.includes('category')
      || headerKey.includes('realDate'));
  } else {
    return (headerKey.includes('title'));
  }
}

export const checkCommunicationsCellId = (cellId, isMobile) => {
  if (!isMobile) {
    return (cellId.includes('title')
      || cellId.includes('category')
      || cellId.includes('realDate'));
  } else {
    return (cellId.includes('title'));
  }
}

export const ALERT_HEADERS = [{
  header: 'Title',
  key: 'title'
},
{
  header: 'Body',
  key: 'body'
},
{
  header: 'Date',
  key: 'realDate'
},
{
  header: 'publishedDate',
  key: 'date'
},
{
  header: 'createdDate',
  key: 'createdDate'
},
{
  header: 'UpdatedDate',
  key: 'updateDate'
},
{
  header: 'Status',
  key: 'status'
},
{
  header: 'Category',
  key: 'category'
},
{
  header: 'SubCategory',
  key: 'subCategory'
},
{
  header: 'announcementEventStartTime',
  key: 'announcementEventStartTime'
},
{
  header: 'announcementEventEndTime',
  key: 'announcementEventEndTime'
},
{
  header: 'Instance',
  key: 'instance'
}];