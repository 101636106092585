import React from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionItem } from 'carbon-components-react';
import { updateNotificationIdFilter } from '../../../reducers/CommunicationsReducer';

import "react-big-calendar/lib/css/react-big-calendar.css";
import parse from 'html-react-parser';
import MonthView from './MonthView';
import WeekView from './WeekView';
import DayView from './DayView';
import AgendaView from './AgendaView';
import { cleanHTML, upperCaseFirstLetter } from '../../Helper/ComponentHelper';
import { Calendar, momentLocalizer } from 'react-big-calendar';

const moment = require('moment'); 

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1
  },
});

const CommunicationsCalendar = (props) => {
  const localizer = momentLocalizer(moment);

  const onEventClick = (event) => {
    props.updateNotificationIdFilter(event.id);

    props.filterCommunications({
      selectedNotificationId: event.id
    });

    props.closeModal();

    props.setNotificationModalOpen(true);
  }

  const styleSetter = (event) => {
    return { className: determineCategoryColor(event.category, false) }
  }

  const determineCategoryColor = (category, isEventAgenda) => {
    if (category) {
      switch (category) {
        case 'Announcement':
          return (isEventAgenda ? '' : 'borderBlack') + ' announcementEventCalendar';
        case 'Incident':
          return (isEventAgenda ? '' : 'borderBlack') + ' incidentEventCalendar';
        case 'Maintenance':
          return (isEventAgenda ? '' : 'borderBlack') + ' maintenanceEventCalendar';
        case 'Security':
          return (isEventAgenda ? '' : 'borderBlack') + ' securityEventCalendar';
        case 'Alert':
          return (isEventAgenda ? '' : 'borderBlack') + ' alertEventCalendar';
        default:
          return '';
      }
    }
  }

  const renderTitle = (event) => {
    return <span>
      <strong>{event.title} </strong>
      [Category:
      <span className="italic">{event.event.category} </span>
      , Status:
      <span className="italic">{upperCaseFirstLetter(event.event.status)}</span>
      ]
    </span>
  }

  const renderEvent = (event) => {
    return (
      <span>
        {event.title}
        <br />
        <br />
        <Accordion className={"calendarAccordionContainer " + determineCategoryColor(event.event.category, true)}>
          <AccordionItem title='Information' key={event.event.id}>
            {createCommunicationInfo(event.event)}
          </AccordionItem>
        </Accordion>
      </span>
    );
  }

  const createCommunicationInfo = (notification) => {
    return <React.Fragment>
      <strong>Category</strong>
      <br />
      {notification.category}
      <br />
      <br />
      <strong>Status</strong>
      <br />
      {upperCaseFirstLetter(notification.status)}
      <br />
      <br />
      <strong>Message</strong>
      <br />
      <div className="eventCalendarDescription">{parse(cleanHTML(notification.body))}</div>
    </React.Fragment>
  }

  return (
    <Calendar
      className="calendar"
      localizer={localizer}
      defaultDate={moment().toDate()}
      events={props.calendarEvents}
      showMultiDayTimes
      startAccessor="start"
      endAccessor="end"
      onSelectEvent={(event) => onEventClick(event)}
      eventPropGetter={styleSetter}
      views={{ agenda: AgendaView, month: true, week: true, day: true }}
      components={{
        event: renderTitle,
        agenda: {
          event: renderEvent
        }
      }} />
  );
}

export default connect(
  (state) => ({
  }), {
  updateNotificationIdFilter
}
)(CommunicationsCalendar);